.close ::before{
  content: 'X';
  visibility: visible;
  color: "black";
  font-weight: bold;
}

.sr-only::before{
  visibility: hidden;
}

.close{
  /*background-color: red; */
  visibility: hidden;
  position: absolute;
  right: 32px;
  top: 15px;
  width: 20px;
  height: 20px;
  padding: 8px;
  background-color: rgb(250, 250, 250);
}