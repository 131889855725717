.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  transition: border-color 0.3s ease-in-out;
  width: 100px;
  height: 100px;
}


.dropzone:hover {
  border-color: #555;
}

.thumbs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.thumb {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease-in-out;
}

.thumb:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}

.thumb-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.thumb img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.thumb-delete {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background-color: rgba(255, 0, 0, 0.8);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

/* .thumb-delete:hover {
  background-color: rgba(255, 0, 0, 0.8);
} */
