nav {
  background-color: #1C1A1A;
}

a.nav-link {
  color: #F4F6F6 !important;
}

a:hover{
  color: #A2A2A2 !important;
}

.nav-margin {
  margin: 30;
}

/* @media (max-width: 400px) {
    .nav-margin {
    margin-left: 8;
  }
} */