.logo {
  width: 250px;
  height: auto;
  margin-left: 10%;
}

div.container {
  width: 400px;
  height: auto;
  padding: 20px;
}

a#recuperarSenha {
  color: #666;
  text-decoration: none;
}
